import { ProgressSpinner } from 'primereact/progressspinner';

//css
import '../../assets/css/progressspinner.css';

export default function Loading() {
  return (
	<section>
		<div className="container">
			<div className="row align-items-center">
				<div className="col-12">
					<ProgressSpinner style={{width: '50px', height: '50px'}} className="brj-spinner" strokeWidth="3" animationDuration="1.5s" aria-label="Die Seite lädt..." /> Inhalte werden geladen...
				</div>
			</div>
		</div>
	</section>
  );
}
