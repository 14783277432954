import React from 'react'
import ReactDOM from 'react-dom/client'
import MainApp from './MainApp'
import ProjectConstants from "./projectConstants"
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/basic.scss'
import './assets/css/main.scss'


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>,
)
