import { Link, useMatch } from "react-router-dom";
import PathConstants from "../../../routes/PathConstantsMain";
import { Container, Navbar, Nav } from "react-bootstrap";

//images
import brjLogo from '../../../assets/images/Logo_BRJ_A2.png'


export default function Header() {

  return (
	<header>
		<div className="container">
			<div className="row menu">
				<Navbar expand="lg" className="rounded py-4">
				  <Container fluid className="justify-content-center text-center">
					<Navbar.Toggle aria-controls="brj-navbar" aria-label="Navigation umschalten" />
					<Navbar.Collapse id="brj-navbar" className="d-lg-flex">
					<Navbar.Brand href="https://www.brj-berlin.de/" target="_blank" className="col-lg-2"><img src={brjLogo} alt="BRJ-Logo" /></Navbar.Brand>
					  <Nav className="col-lg-7 justify-content-lg-center">
						<Nav.Link as={Link} to={PathConstants.START} active={Boolean(useMatch(PathConstants.START))}>Start</Nav.Link>
						<Nav.Link as={Link} to={PathConstants.HINWEIS} active={Boolean(useMatch(PathConstants.HINWEIS))}>Hinweis</Nav.Link>
						<Nav.Link as={Link} to={PathConstants.ABLAUF} active={Boolean(useMatch(PathConstants.ABLAUF))}>Ablauf</Nav.Link>
						<Nav.Link as={Link} to={PathConstants.ANGEBOT} active={Boolean(useMatch(PathConstants.ANGEBOT))}>Angebot</Nav.Link>
					  </Nav>
					  <div className="d-lg-flex col-lg-3 justify-content-lg-end">
						<Link id="BearbeitungsstandMenuLink" className={"btn btn-outline-primary br-light p-2" + (Boolean(useMatch(PathConstants.BEARBEITUNGSSTAND))?" active":"")} to={PathConstants.BEARBEITUNGSSTAND}><span className="emoji">🔁</span> Bearbeitungsstand</Link>
					  </div>
					</Navbar.Collapse>
				  </Container>
				</Navbar>
			</div>
		</div>
	</header>
  );
}
