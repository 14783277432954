import { Link } from "react-router-dom"

//css
import "../../assets/css/Page404.css"

export default function Page404() {
    return (
        <section className="page-404">
            <h1 className="title">404 - Seite existiert nicht</h1>
            <p className="not-found-message">Hier ist etwas schief gelaufen. Wo kann ich mich beschweren? 😉</p>
            <Link to="/" className="btn btn-primary mt-3">Zurück auf die Startseite</Link>
        </section>
    )
}
