const ProjectConstants = {
    COMPANY_NAME: "Berliner Rechtshilfefonds Jugendhilfe e. V.",
    COMPANY_NAME_SHORT: "BRJ e. V.",
    COMPANY_MAIL: "info@brj-berlin.de",
    PROJECT_NAME: "Externe Meldestelle HinSchG",
    PROJECT_NAME_SHORT: "Meldestelle HinSchG",
	COLORS : {
	  error: 'var(--red-500)',
	  warning: 'var(--yellow-500)',
	  ok: 'var(--green-500)',
	},
	WHISTLEBLOW_FUNCTIONS : [
        { name: 'Leitung', code: 'LTG' },
        { name: 'Verwaltung', code: 'VWT' },
        { name: 'Geschäftsführung', code: 'GSF' },
        { name: 'Vorstand', code: 'VST' },
        { name: 'Aufsichtsrat', code: 'ASR' },
        { name: 'Andere', code: 'ADR' }
    ],
	WHISTLEBLOW_NUMBER_AFFECTED : [
        { name: 'Ausschließlich ich', code: '1' },
        { name: '2', code: '2' },
        { name: '3', code: '3' },
        { name: '4', code: '4' },
        { name: '5', code: '5' },
        { name: 'mehr als 5', code: '999' }
    ],
    MAX_UPLOAD_SIZE: 10485760 /* if this is changed, change also server/api/config.php/max_size_all_files */
};

export default ProjectConstants