import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import axios from 'axios';
import routes from "./routes/main.jsx"
import Layout from "./components/page/main/Layout"
import getCSRFToken from './components/Csrf';
import Page404 from "./pages/main/Page404"
window.csrf_token_status = "unavailable";

function MainApp() {
  axios.defaults.withCredentials = true;
  const [csrfTokenReady, setCsrfTokenReady] = useState(false);
  
  useEffect(() => {
	(async () => {
		await getCSRFToken();
		setCsrfTokenReady(true);
	})();
  }, []);
  
  const router = createBrowserRouter([
    {
      // parent route component
      element: <Layout />,
      // your custom routing error component
      errorElement: <Page404 />,
      // child route components
      children: routes
    }], {
	  basename: import.meta.env.VITE_APP_BASE_URL,
	})

  return (
  <>
	{csrfTokenReady ? <RouterProvider router={router} /> : "" }
  </>
  )
}

export default MainApp