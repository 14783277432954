const PathConstants = {
    START: "/",
    HINWEIS: "/hinweis",
    ABLAUF: "/ablauf",
    ANGEBOT: "/angebot",
    BEARBEITUNGSSTAND: "/bearbeitungsstand",
    UEBER_UNS: "/ueber_uns",
    KONTAKT: "/kontakt",
    IMPRESSUM: "/impressum",
    DATENSCHUTZ: "/datenschutz"
}

export default PathConstants