import { Link, useMatch } from "react-router-dom";
import PathConstants from "../../../routes/PathConstantsMain";
import { Container, Navbar, Nav } from "react-bootstrap";
import ProjectConstants from "../../../projectConstants";

export default function Footer() {
  return (
	<footer className="footer mt-auto py-4">
		<div className="container">
			<div className="footer_menu">
				<Navbar expand="lg">
				  <Container fluid className="justify-content-center">
					<Navbar.Brand href="https://www.brj-berlin.de/" target="_blank" title="Zur Webseite des BRJ e. V.">© {(new Date().getFullYear())} {ProjectConstants.COMPANY_NAME}</Navbar.Brand>
					<Navbar.Collapse className="justify-content-lg-center">
					  <Nav className="text-center">
						<Nav.Link as={Link} to={PathConstants.UEBER_UNS} active={Boolean(useMatch(PathConstants.UEBER_UNS))}>Über uns</Nav.Link>
						<Nav.Link as={Link} to={PathConstants.KONTAKT} active={Boolean(useMatch(PathConstants.KONTAKT))}>Kontakt</Nav.Link>
						<Nav.Link as={Link} to={PathConstants.IMPRESSUM} active={Boolean(useMatch(PathConstants.IMPRESSUM))}>Impressum</Nav.Link>
						<Nav.Link as={Link} to={PathConstants.DATENSCHUTZ} active={Boolean(useMatch(PathConstants.DATENSCHUTZ))}>Datenschutz</Nav.Link>
					  </Nav>
					</Navbar.Collapse>
				  </Container>
				</Navbar>
			</div>
		</div>
	</footer>
  );
}
