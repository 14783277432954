import React from "react"
import PathConstants from "./PathConstantsMain"

const Start = React.lazy(() => import("../pages/main/Start"))
const Hinweis = React.lazy(() => import("../pages/main/Hinweis"))
const Ablauf = React.lazy(() => import("../pages/main/Ablauf"))
const Angebot = React.lazy(() => import("../pages/main/Angebot"))
const Bearbeitungsstand = React.lazy(() => import("../pages/main/Bearbeitungsstand"))
const Ueber_uns = React.lazy(() => import("../pages/main/Ueber_uns"))
const Kontakt = React.lazy(() => import("../pages/main/Kontakt"))
const Impressum = React.lazy(() => import("../pages/main/Impressum"))
const Datenschutz = React.lazy(() => import("../pages/main/Datenschutz"))

const routes = [
    { path: PathConstants.START, element: <Start /> },
    { path: PathConstants.HINWEIS, element: <Hinweis /> },
    { path: PathConstants.ABLAUF, element: <Ablauf /> },
    { path: PathConstants.ANGEBOT, element: <Angebot /> },
    { path: PathConstants.BEARBEITUNGSSTAND, element: <Bearbeitungsstand /> },
    { path: PathConstants.UEBER_UNS, element: <Ueber_uns /> },
    { path: PathConstants.KONTAKT, element: <Kontakt /> },
    { path: PathConstants.IMPRESSUM, element: <Impressum /> },
    { path: PathConstants.DATENSCHUTZ, element: <Datenschutz /> },
]
export default routes
