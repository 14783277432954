import axios from "axios";

export default function getCSRFToken() {
  return new Promise((resolve, reject) => {
    if (window.csrf_token_status === "available") return resolve();
	
    if (window.csrf_token_status === "requested") {
        setInterval(function(){
           if (window.csrf_token_status === "available") 
               return resolve();
        }, 500);
    } else {
       window.csrf_token_status = "requested";
       
		axios.get(import.meta.env.VITE_API_BASE_URL, {params: {action: 'getCSRFToken'}})
       .then((response)=>{
		  axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data;
          window.csrf_token_status = "available";
          return resolve();
       })
    }
  });
};