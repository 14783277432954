import React, { lazy, Suspense } from 'react'
import { Outlet, ScrollRestoration } from "react-router-dom"
import { ScrollTop } from 'primereact/scrolltop'
import Loading from "../Loading"
import Header from "./Header"
import Footer from "./Footer"

//css
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';

export default function Layout() {
    return (
        <>
            <Header />
            <main>
				<ScrollRestoration />
				<Suspense fallback={<Loading />}>
					<Outlet />
				</Suspense>
				<ScrollTop threshold={100} className="rounded-3 bg-secondary" />
            </main>
            <Footer />
        </>
    )
}